import "./InputButton.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTooltip } from "../../../utils/hook"
import SvgIcon from "../SvgIcon"

const InputButton = ({
  icon,
  disabled,
  onClick,
  tooltipText,
  showTooltip,
  alignItemAbove,
  tooltipTop,
  active,
  danger,
  tabIndex,
}) => {
  const [tooltip, setTooltip, closeTooltip] = useTooltip(10, 0)

  const iconClass = classNames("input-button-icon", {
    "input-button-icon-danger": danger,
  })

  const buttonClass = classNames({
    "input-button": true,
    "input-button-active": active,
  })

  return (
    <div
      onMouseEnter={e => {
        setTooltip({
          event: e,
          text: tooltipText,
          alignItemAbove,
          tooltipTop,
        })
      }}
      onMouseLeave={closeTooltip}
      className="input-button-wrapper"
    >
      <button tabIndex={tabIndex} onClick={onClick} disabled={disabled} className={buttonClass}>
        {showTooltip && tooltip}
        <SvgIcon icon={icon} className={iconClass} />
      </button>
    </div>
  )
}

InputButton.propTypes = {
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  alignItemAbove: PropTypes.bool,
  danger: PropTypes.bool,
  tabIndex: PropTypes.string,
}

InputButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  tooltipText: "",
  alignItemAbove: false,
  danger: false,
  tabIndex: "0",
}

export default InputButton
