import { toast } from "react-toastify"
import urljoin from "url-join"

import { PromptVariant } from "../../components/atoms/Prompt"
import ACTIONS from "../../constants/ACTIONS"
import paymentService from "../../services/payment-service"
import { asyncTimeout } from "../../utils/global-utils"
import { getBuySeatsPlans } from "../../utils/payment-utils"
import { showPrompt } from "../app/appActions"
import { clearForm } from "../forms/formsActions"
import { getTeamAccounts } from "../team-management/teamManagementActions"
import { getUserData, getUserProduct } from "../user/userActions"

function getPaymentPlansSuccess(paymentPlans) {
  return { type: ACTIONS.GET_PAYMENT_PLANS, paymentPlans }
}

function getPaymentPlans() {
  return async dispatch => {
    try {
      const paymentPlans = await paymentService.getPaymentPlans()
      dispatch(getPaymentPlansSuccess(paymentPlans.data.result.items))
    } catch (error) {}
  }
}

function getAvailableSeatsForUserSuccess(availableSeats) {
  return { type: ACTIONS.GET_AVAILABLE_SEATS_FOR_USER, availableSeats }
}

function getAvailableSeatsForTeamSuccess(availableSeats) {
  return { type: ACTIONS.GET_AVAILABLE_SEATS_FOR_TEAM, availableSeats }
}

function getAvailableSeatsForTeam() {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam } = getState().teamManagement
      const { initialUserId } = selectedTeam
      const availableSeats = await paymentService.getAvailableSeats(initialUserId)
      dispatch(getAvailableSeatsForTeamSuccess(availableSeats.data.result.items))
    } catch (error) {}
  }
}

function getAvailableSeatsForUser() {
  return async (dispatch, getState) => {
    try {
      /**
       * For fetching all user seats (only if user has their own team)
       * BE expects us to send teamID, so any team that User is the owner of will do
       * */
      const userID = getState().user.profile.id
      const { userTeams } = getState().teamManagement
      if (userTeams.items.find(userTeam => userTeam.isTeamOwner)) {
        const availableSeats = await paymentService.getAvailableSeats(userID)
        dispatch(getAvailableSeatsForUserSuccess(availableSeats.data.result.items))
      }
    } catch (error) {}
  }
}

function getAccountSpecificPricesSuccess(accountSpecificPrices) {
  return { type: ACTIONS.GET_ACCOUNT_SPECIFIC_PRICES, accountSpecificPrices }
}

/** Fetching specific prices when we are upgrading account to new Pricing Tier */
function getAccountSpecificPrices(accountID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const accountSpecificPrices = await paymentService.getAccountSpecificPrices(
        initialUserId,
        accountID,
      )
      dispatch(getAccountSpecificPricesSuccess(accountSpecificPrices.data.result))
    } catch (error) {}
  }
}

function getPaymentCustomerSuccess(customer) {
  return { type: ACTIONS.GET_PAYMENT_CUSTOMER, customer }
}

function getPaymentCustomerFail() {
  return { type: ACTIONS.GET_PAYMENT_CUSTOMER, customer: false }
}

function getPaymentCustomer() {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const customerData = await paymentService.getPaymentCustomer(initialUserId)
      dispatch(getPaymentCustomerSuccess(customerData.data.result))
      return customerData.data.result || {}
    } catch (error) {
      dispatch(getPaymentCustomerFail())
      return false
    }
  }
}

function createSubscription(keepFreeTrial) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const subscription = await paymentService.createSubscription(initialUserId, { keepFreeTrial })
      return subscription.data.result.id
    } catch (error) {
      return false
    }
  }
}

function getSubscriptionInfo(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const subscriptionInfo = await paymentService.getSubscriptionInfo(
        initialUserId,
        subscriptionID,
      )
      return subscriptionInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function getPricePreviewSuccess(pricePreview) {
  return { type: ACTIONS.GET_PRICE_PREVIEW, pricePreview }
}

function getPricePreview(getForPlatform) {
  return async (dispatch, getState) => {
    try {
      const { selectedTeam, userTeams } = getState().teamManagement
      const userID = getState().user.profile.id

      const initialUserId = getForPlatform
        ? userTeams.items.find(userTeam => userTeam.isTeamOwner)?.initialUserId || userID
        : selectedTeam.initialUserId

      const { pricingPlans } = getState().payment

      const { formData } = getState().forms
      const { teamId } = selectedTeam

      const plans = []
      pricingPlans.forEach(plan => {
        if (formData[`${plan.name}-seats`] > 0) {
          plans.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`],
          })
        }
      })
      const data = {
        plans,
        teamId,
      }
      const pricePreview = await paymentService.getPricePreview(initialUserId, data)
      dispatch(getPricePreviewSuccess(pricePreview.data.result))
    } catch (error) {}
  }
}

function getLastUsedCardSuccess(savedCard) {
  return { type: ACTIONS.GET_LAST_USED_CARD, savedCard }
}

function getLastUsedCard() {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const savedCard = await paymentService.getLastUsedCard(initialUserId)
      dispatch(getLastUsedCardSuccess(savedCard.data.result))
      return true
    } catch (error) {
      dispatch(getLastUsedCardSuccess({}))
      return false
    }
  }
}

function changeCard(stripeToken) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      await paymentService.changeCard(initialUserId, {
        stripeToken,
      })
      return true
    } catch (error) {
      return false
    }
  }
}

function createCustomer(stripeToken, userEmail, referral, companyName, taxId, address) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const response = await paymentService.createCustomer(initialUserId, {
        stripeToken,
        userEmail,
        referral,
        companyName,
        taxId,
        address,
      })
      if (response.data.result.clientSecret) {
        return response.data.result.clientSecret
      }
      if (response.data.result.failReason) {
        if (response.data.result.failReason === "NEW_PAYMENT_METHOD") {
          dispatch(
            showPrompt(
              PromptVariant.ERROR,
              "Warning",
              "Transaction declined by the bank. Please attach a valid credit card.",
            ),
          )
        }
        return false
      }
      return true
    } catch (error) {}
  }
}

function getAllSubscriptionsSuccess(allSubscriptions) {
  return { type: ACTIONS.GET_ALL_SUBSCRIPTIONS, allSubscriptions }
}

function getAllSubscriptions() {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const allSubscriptions = await paymentService.getAllSubscriptions(initialUserId)
      dispatch(getAllSubscriptionsSuccess(allSubscriptions.data.result.items))
    } catch (error) {}
  }
}

function buySeats() {
  return async (dispatch, getState) => {
    try {
      const { initialUserId, teamId } = getState().teamManagement.selectedTeam
      const { plans } = getBuySeatsPlans()

      const data = {
        plans,
        teamId,
      }

      const response = await paymentService.buySeats(initialUserId, data)
      if (response.data.result.clientSecret) {
        return {
          clientSecret: response.data.result.clientSecret,
          getNewSeats: async () => {
            await asyncTimeout(2500)
            await Promise.all([
              dispatch(getUserData()),
              dispatch(getAllSubscriptions()),
              dispatch(getAvailableSeatsForUser()),
              dispatch(getAvailableSeatsForTeam()),
            ])
          },
        }
      }
      if (response.data.result.failReason) {
        switch (response.data.result.failReason) {
          case "NEW_PAYMENT_METHOD":
            dispatch(
              showPrompt(
                PromptVariant.ERROR,
                "Warning",
                "Transaction declined by the bank. Please attach a valid credit card.",
              ),
            )
            break
        }
        return false
      }

      await asyncTimeout(2500)
      await Promise.all([
        dispatch(getUserData()),
        dispatch(getAllSubscriptions()),
        dispatch(getAvailableSeatsForUser()),
        dispatch(getAvailableSeatsForTeam()),
      ])

      return true
    } catch (error) {
      return false
    }
  }
}

function checkPromoCode(promoCode) {
  return async () => {
    try {
      const promoCodeInfo = await paymentService.checkPromoCode(promoCode)
      return promoCodeInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function submitPromoCode(promoCode, subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const promoCodeInfo = await paymentService.submitPromoCode(
        initialUserId,
        subscriptionID,
        promoCode,
      )
      return promoCodeInfo.data.result
    } catch (error) {
      return false
    }
  }
}

function cancelSubscription(reason, callback) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const accountID = getState().account.activeAccountID
      await paymentService.cancelSubscription(userID, accountID, { reason })
      await paymentService.cancelSubscriptionReason(userID, { reason })
      dispatch(clearForm())
      await callback()
      await dispatch(getTeamAccounts())
      toast.success("Subscription successfully canceled")
    } catch (error) {}
  }
}

function changeAccountSubscription() {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const accountID = getState().account.activeAccountID
      const planId = getState().forms.formData["pricing-tier"]
      await paymentService.changeAccountSubscription(initialUserId, accountID, { planId })
      await dispatch(getAccountSpecificPrices(accountID))
      await dispatch(getTeamAccounts())
      toast.success("Subscription successfully changed")
    } catch (error) {
      toast.error("Something went wrong. Please try again later!")
    }
  }
}

function paySubscription(stripeToken, subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const response = await paymentService.paySubscription(initialUserId, subscriptionID, {
        stripeToken,
      })
      if (response.data.result.clientSecret) {
        return response.data.result.clientSecret
      }
      if (response.data.result.failReason) {
        switch (response.data.result.failReason) {
          case "NEW_PAYMENT_METHOD":
            dispatch(
              showPrompt(
                PromptVariant.ERROR,
                "Warning",
                "Transaction declined by the bank. Please attach a valid credit card.",
              ),
            )
            break
        }
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function getLastInvoiceAmount(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const lastInvoice = await paymentService.getLastInvoiceAmount(initialUserId, subscriptionID)
      return lastInvoice.data.result
    } catch (error) {
      return false
    }
  }
}

function endFreeTrial(subscriptionID) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      const response = await paymentService.endFreeTrial(initialUserId, subscriptionID)
      if (response.data.result.clientSecret) {
        return {
          clientSecret: response.data.result.clientSecret,
        }
      }
      return true
    } catch (error) {
      return false
    }
  }
}

function updateCompanyInvoiceInfo(customerID, data) {
  return async (dispatch, getState) => {
    try {
      const { initialUserId } = getState().teamManagement.selectedTeam
      await paymentService.updateCompanyInvoiceInfo(initialUserId, customerID, data)
      toast.success("Company info successfully updated")
      return true
    } catch (error) {
      return false
    }
  }
}

function transferSeats(destinationUserId) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const { pricingPlans } = getState().payment
      const { formData } = getState().forms
      const seats = []
      pricingPlans.forEach(plan => {
        if (formData[`${plan.name}-seats`] > 0) {
          seats.push({
            planId: +plan.id,
            quantity: +formData[`${plan.name}-seats`],
          })
        }
      })
      await paymentService.transferSeats(userID, seats, destinationUserId)
      dispatch(getAvailableSeatsForUser())
      dispatch(getAvailableSeatsForTeam())
      toast.success("Seats successfully transferred")
      return true
    } catch (error) {
      toast.error("Transfer failed. Please try again later")
      return false
    }
  }
}

function generateRegistrationLink(creditCount) {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const generatedLink = await paymentService.generateRegistrationLink(userID, {
        redeems: creditCount,
      })

      const productInfo = await dispatch(getUserProduct())

      return urljoin(
        productInfo.result.domain,
        `/register?invitationID=${generatedLink.data.result.invitationId}`,
      )
    } catch (error) {
      return false
    }
  }
}

function getTeamsWithPaymentPermissionsSuccess(teams) {
  return { type: ACTIONS.GET_TEAMS_WITH_PAYMENT, teams }
}

function getTeamsWithPaymentPermissions() {
  return async (dispatch, getState) => {
    try {
      const userID = getState().user.profile.id
      const teamsWithPayment = await paymentService.getTeamsWithPaymentPermissions(userID)
      const result = teamsWithPayment.data.result.items
      dispatch(getTeamsWithPaymentPermissionsSuccess(result))
    } catch (error) {
      return false
    }
  }
}

function getAllInvoices(initialUserId, query = "") {
  return async () => {
    try {
      return await paymentService.getAllInvoices(initialUserId, query)
    } catch (error) {
      return false
    }
  }
}

export {
  buySeats,
  cancelSubscription,
  changeAccountSubscription,
  changeCard,
  checkPromoCode,
  createCustomer,
  createSubscription,
  endFreeTrial,
  generateRegistrationLink,
  getAccountSpecificPrices,
  getAllInvoices,
  getAllSubscriptions,
  getAvailableSeatsForTeam,
  getAvailableSeatsForUser,
  getLastInvoiceAmount,
  getLastUsedCard,
  getPaymentCustomer,
  getPaymentPlans,
  getPricePreview,
  getSubscriptionInfo,
  getTeamsWithPaymentPermissions,
  paySubscription,
  submitPromoCode,
  transferSeats,
  updateCompanyInvoiceInfo,
}
