import { STATUS_COLOR } from "../components/atoms/Status"

export const LEADS_FILTERS = {
  NOT_VERIFIED_EMAILS: {
    name: "NOT_VERIFIED_EMAILS",
    text: "Unverified emails",
  },
  VERIFIED_EMAILS: {
    name: "VERIFIED_EMAILS",
    text: "Verified emails",
  },
  DISCOVERED: {
    name: "DISCOVERED",
    id: 1,
    backgroundColor: STATUS_COLOR.GRAY,
    text: "Discovered",
    leadStatus: true,
    icon: "check-16",
  },
  CONNECTED_PENDING: {
    name: "CONNECTED_PENDING",
    id: 2,
    backgroundColor: STATUS_COLOR.YELLOW,
    text: "Connection pending",
    leadStatus: true,
    icon: "hourglass-empty-16",
  },
  CONNECTED_NOT_REPLIED: {
    name: "CONNECTED_NOT_REPLIED",
    id: 3,
    backgroundColor: STATUS_COLOR.YELLOW,
    text: "Connected, didn't reply",
    leadStatus: true,
    icon: "hourglass-empty-16",
  },
  CONNECTED_REPLIED: {
    name: "CONNECTED_REPLIED",
    id: 4,
    backgroundColor: STATUS_COLOR.GREEN,
    text: "Connected & replied",
    leadStatus: true,
    icon: "checks-16",
  },
  REPLIED_NOT_CONNECTED: {
    name: "REPLIED_NOT_CONNECTED",
    id: 5,
    backgroundColor: STATUS_COLOR.RED,
    text: "Replied, didn't connect",
    leadStatus: true,
    icon: "circle-half-diagonal-16",
  },
  OUT_OF_OFFICE: {
    name: "OUT_OF_OFFICE",
    id: 6,
    backgroundColor: STATUS_COLOR.YELLOW,

    text: "Out of office",
    leadStatus: true,
    icon: "circle-half-diagonal-16",
  },
  COMPLEX_STEPS: {
    name: "COMPLEX_STEPS",
    text: "Selected steps",
  },
  NAME: {
    name: "NAME",
    text: "Full name...",
  },
  COMPANY: {
    name: "COMPANY",
    text: "Company...",
  },
  OCCUPATION: {
    name: "OCCUPATION",
    text: "Occupation...",
  },
  HEADLINE: {
    name: "HEADLINE",
    text: "Headline...",
  },

  ADVANCED: {
    name: "ADVANCED",
    text: "advanced",
  },
}

export const advancedFilters = [
  LEADS_FILTERS.NAME,
  LEADS_FILTERS.COMPANY,
  LEADS_FILTERS.OCCUPATION,
  LEADS_FILTERS.HEADLINE,
]

export const getAllLeadStatuses = () => {
  return Object.values(LEADS_FILTERS).filter(leadFilter => leadFilter.leadStatus)
}
