export const curveNames = {
  PROFILE_VIEW: "PROFILE_VIEW",
  PROFILE_FOLLOW: "PROFILE_FOLLOW",
  INVITATION_SENT: "INVITATION_SENT",
  MESSAGE_SENT: "MESSAGE_SENT",
  INMAIL_SENT: "INMAIL_SENT",
  EMAIL_SENT: "EMAIL_SENT",
  INVITATION_ACCEPTED: "INVITATION_ACCEPTED",
  MESSAGE_REPLY: "MESSAGE_REPLY",
  INVITATION_ACCEPTED_RATE: "INVITATION_ACCEPTED_RATE",
  MESSAGE_REPLY_RATE: "MESSAGE_REPLY_RATE",
  EMAIL_OPENED: "EMAIL_OPENED",
  EMAIL_CLICKED: "EMAIL_CLICKED",
  EMAIL_OPEN_RATE: "EMAIL_OPEN_RATE",
  EMAIL_CLICK_RATE: "EMAIL_CLICK_RATE",
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
  BOUNCE_RATE: "BOUNCE_RATE",
  INVITATION_VIA_EMAIL_SENT: "INVITATION_VIA_EMAIL_SENT",
}

export const FILTER_BY_REPORT_TYPES = [
  {
    name: "LinkedIn",
    type: "LINKEDIN",
  },
  {
    name: "Email",
    type: "EMAIL",
  },
  {
    name: "Data enrichment",
    type: "ENRICHMENT",
  },
]

export const statisticsCurves = {
  PROFILE_VIEW: {
    ID: 1,
    color: "--text-data-01",
    label: "Views",
    key: "views",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  PROFILE_FOLLOW: {
    ID: 2,
    color: "--text-data-02",
    label: "Follows",
    key: "follows",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  INVITATION_SENT: {
    ID: 3,
    color: "--text-data-03",
    label: "Connections sent",
    key: "connectionsSent",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  INVITATION_VIA_EMAIL_SENT: {
    ID: 18,
    color: "--text-data-04",
    label: "Invite via email sent",
    key: "invitationViaEmailSent",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  MESSAGE_SENT: {
    ID: 4,
    color: "--text-data-05",
    label: "Messages sent",
    key: "messagesSent",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  INMAIL_SENT: {
    ID: 5,
    color: "--text-data-06",
    label: "InMails sent",
    key: "inMailsSent",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  EMAIL_SENT: {
    ID: 10,
    color: "--text-data-07",
    label: "Emails sent",
    key: "emailsSent",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  INVITATION_ACCEPTED: {
    ID: 6,
    color: "--text-data-08",
    label: "Connections accepted",
    key: "connectionsAccepted",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },
  MESSAGE_REPLY: {
    ID: 7,
    color: "--text-data-09",
    label: "Replies received",
    key: "repliesReceived",
    channel: [FILTER_BY_REPORT_TYPES[0].type, FILTER_BY_REPORT_TYPES[1].type],
  },
  MESSAGE_REPLY_RATE: {
    ID: 9,
    color: "--text-data-10",
    label: "Response rate",
    percentage: true,
    key: "responseRate",
    channel: [FILTER_BY_REPORT_TYPES[0].type, FILTER_BY_REPORT_TYPES[1].type],
  },
  INVITATION_ACCEPTED_RATE: {
    ID: 8,
    color: "--text-data-01",
    label: "Acceptance rate",
    percentage: true,
    key: "acceptanceRate",
    channel: [FILTER_BY_REPORT_TYPES[0].type],
  },

  EMAIL_OPENED: {
    ID: 11,
    color: "--text-data-02",
    label: "Email opened",
    key: "emailOpened",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  EMAIL_CLICKED: {
    ID: 12,
    color: "--text-data-03",
    label: "Email clicked",
    key: "emailClicked",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  EMAIL_REPLY_RATE: {
    ID: 13,
    color: "--text-data-08",
    label: "Response rate",
    percentage: true,
    hide: true,
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  EMAIL_OPEN_RATE: {
    ID: 14,
    color: "--text-data-04",
    label: "Email open rate",
    percentage: true,
    key: "emailOpenRate",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  EMAIL_CLICK_RATE: {
    ID: 15,
    color: "--text-data-05",
    label: "Email click rate",
    percentage: true,
    key: "emailClickRate",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
  EMAIL_VERIFIED: {
    ID: 16,
    color: "--text-data-06",
    label: "Email verified",
    key: "emailVerified",
    channel: [FILTER_BY_REPORT_TYPES[2].type],
  },
  BOUNCE_RATE: {
    ID: 17,
    color: "--text-data-07",
    label: "Bounce rate",
    percentage: true,
    key: "bounceRate",
    channel: [FILTER_BY_REPORT_TYPES[1].type],
  },
}

export const getStatisticsObject = statisticsID => {
  for (const curve in statisticsCurves) {
    if (statisticsCurves[curve] && statisticsCurves[curve].ID === +statisticsID) {
      return statisticsCurves[curve]
    }
  }
}
