import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  proxyCountries: [],
  infoModalData: { show: false, type: "info" },
  allSupportedTags: [],
  accountStatuses: [],
  whitelabelUrl: undefined,
  cancellationReasons: { items: [] },
  renderEndFreeTrialModal: false,
  whitelabelPermissions: [],
  promptData: { show: false },
  modalData: { previuosData: {} },
  modalLoader: { loading: false },
  overlayData: { show: false },
}

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_PROXY_COUNTRIES: {
      return {
        ...state,
        proxyCountries: action.proxyCountries,
      }
    }

    case ACTIONS.SET_INFO_MODAL_DATA:
    case ACTIONS.SHOW_PREVIOUS_INFO_MODAL:
    case ACTIONS.SHOW_INFO_MODAL:
    case ACTIONS.HIDE_INFO_MODAL: {
      return {
        ...state,
        infoModalData: action.infoModalData,
      }
    }

    case ACTIONS.SHOW_PROMPT: {
      return {
        ...state,
        promptData: { ...action.payload, show: true },
      }
    }

    case ACTIONS.CLOSE_PROMPT: {
      return {
        ...state,
        promptData: { show: false },
      }
    }

    case ACTIONS.SHOW_MODAL: {
      return {
        ...state,
        modalData: { ...action.payload, show: true },
      }
    }

    case ACTIONS.CLOSE_MODAL: {
      return {
        ...state,
        modalData: { show: false },
      }
    }

    case ACTIONS.SHOW_OVERLAY: {
      return {
        ...state,
        overlayData: { ...action.payload, show: true },
      }
    }

    case ACTIONS.CLOSE_OVERLAY: {
      return {
        ...state,
        overlayData: { show: false },
      }
    }

    case ACTIONS.GET_ALL_SUPPORTED_TAGS: {
      return {
        ...state,
        allSupportedTags: action.allSupportedTags,
      }
    }

    case ACTIONS.GET_ACCOUNT_STATUSES: {
      return {
        ...state,
        accountStatuses: action.accountStatuses,
      }
    }

    case ACTIONS.CHANGE_WHITELABEL_URL: {
      return {
        ...state,
        whitelabelUrl: action.whitelabelUrl,
      }
    }

    case ACTIONS.GET_CANCELLATION_REASONS: {
      return {
        ...state,
        cancellationReasons: action.cancellationReasons,
      }
    }

    case ACTIONS.TRIGGER_END_FREE_TRIAL_MODAL: {
      return {
        ...state,
        renderEndFreeTrialModal: action.payload,
      }
    }

    case ACTIONS.GET_WHITELABEL_PERMISSIONS: {
      return {
        ...state,
        whitelabelPermissions: action.payload,
      }
    }

    case ACTIONS.LOADING: {
      return {
        ...state,
        modalLoader: {
          ...state.modalLoader,
          loading: action.payload.loader,
        },
      }
    }

    case ACTIONS.USER_LOGOUT: {
      return initialState
    }

    default:
      return { ...state }
  }
}
